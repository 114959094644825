<template>
  <div class="box bunya-box">
    <div class="columns is-mobile">
      <div class="column client-blue-line">
      </div>
    </div>
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <div class="media is-no-border-media">
            <div class="circle-background-wrapper">
              <div class="circle-background">
                <icon stroke="#1BCA8E" width="30" height="30" fill="#FFFFFF" icon="timelineAlbum"/>
              </div>
            </div>
            <div class="media-content">
              <h4 class="no-line-height no-letter-space">{{ album.name }}</h4>
              <h5 class="light opacity">Created by {{ album.user.display_name }} {{ album.created_at | moment("from", "now") }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <snowman-menu :items="actions"/>
        </div>
      </div>
    </div>
    <!-- <hr class="timeline-box"> -->
    <!-- <h3 class="title">{{ album.name }}</h3> -->
    <div class="thumbnails-container" v-if="!isLoading && thumbnails">
      <!-- <span class="image" v-for="(thumbnail, i) in thumbnails" :key="i"> -->
        <!-- <img :src="thumbnail.thumbnail_url"> -->
        <div class="grid-container" v-if="thumbnails.length >= 1">
          <div class="bigImage"><img :src="thumbnails[0].thumbnail_url"></div>
          <div class="small1" v-if="thumbnails.length >= 2">
            <img :src="thumbnails[1].thumbnail_url">
          </div>
          <div class="small2" v-if="thumbnails.length >= 3">
            <img :src="thumbnails[2].thumbnail_url">
            <div class="text-block" v-if="thumbnails.length >= 4"><h1>+{{ thumbnails.length-3 }}</h1></div>
          </div>
        </div>
      <!-- </span> -->
    </div>
    <div v-if="isLoading" class="notification">Please wait while your thumbnails are loading...</div>
    <div class="album-footer" v-if="!isLoading">
      <div class="attachment">
        <icon icon="paperClip" width="30" height="30" class="is-flex"/> <h5 style="color: #42424230" v-if="this.thumbnails">{{ this.thumbnails.length }}</h5>
      </div>
      <div class="download" v-if="false">
        <div class="text">
          album.zip
        </div>
        <div class="album-icon">
          <icon icon="arrowDownLarge" width="10" height="10" class="is-flex"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  .thumbnails-container .image {
    display: inline-block;
    margin-right: 5pt;
    width: 256px;
    height: 256px;
  }

  .album-footer{
    display: flex;
    align-items: center;
    margin-left: -20px;
    background: #fbfbfb;
    margin-right: -20px;
    margin-bottom: -20px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: 15px 20px;
    flex-wrap: nowrap;
    .attachment {
      margin-right: 15px;
      display: flex;
      align-items: center;
    }
    .download {
      margin-right: 20px;
      background-color: #fff;
      display: flex;
      flex-direction: row;
      border-radius: 19px;
      border: 1px solid #EBEBEB;
      align-items: center;
      .text{
        margin-left: 10px;
        margin-right: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
      .album-icon{
        width: 24px;
        height: 24px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid #ebebeb;
        font-size: 10px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 7px;
        margin-top: 7px;
        margin-bottom: 7px;
      }
    }
  }

</style>

<script>
import SnowmanMenu from '@/components/SnowmanMenu'
import Icon from '@/components/Icons/Icon'

export default {
  components: { SnowmanMenu, Icon },
  data () {
    return {
      isLoading: false,
      actions: [
        // {
        //   name: 'Manage Album',
        //   icon: 'edit',
        //   link: 'https://getbunya.com/photographers'
        // },
        {
          name: 'Delete Album',
          icon: 'bin',
          emit: 'album-delete'
        }
      ]
    }
  },
  computed: {
    thumbnails () {
      if (this.isLoading) {
        return []
      }
      return this.$store.getters['albums/albumThumbnails'](this.album.id)
    },
    zipDownloadLink () {
      return false
    }
  },
  name: 'TimelineAlbum',
  mounted () {
    this.loadThumbnails()
  },
  methods: {
    loadThumbnails () {
      this.isLoading = true
      this.$store.dispatch('albums/fetchThumbnails', this.album.id)
        .then((response) => {
          this.isLoading = false
          return this.thumbnails
        })
        .catch(e => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    }
  },
  props: ['album']
}
</script>
