<template>
  <b-table :data="items">
    <template slot-scope="props">
      <b-table-column field="description" label="Item">
        {{ props.row.description }}
      </b-table-column>
      <b-table-column field="quantity" label="Quantity" numeric>
        {{ $n(props.row.quantity) }}
      </b-table-column>
      <b-table-column field="unit_price" label="Unit Price" numeric>
        {{ $n(props.row.unit_price, 'currency', locale) }}
      </b-table-column>
      <b-table-column field="total_price" label="Total Price" numeric>
        {{ $n(props.row.total_price, 'currency', locale) }}
      </b-table-column>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'TimelineFinancialItemsTable',
  props: ['items'],
  computed: {
    locale () {
      return 'en-AU'
    }
  }
}
</script>
