<template>
  <div class="box bunya-box" v-if="!this.task.deleted_at">
    <div class="columns is-mobile">
      <div class="column client-green-line">
      </div>
    </div>
    <div class="level is-mobile timeline-box-level">
      <div class="level-left">
        <div class="level-item">
          <div class="media is-no-border-media">
            <div class="circle-background-wrapper">
              <div class="circle-background">
                <icon width="30" height="30" icon="timelineTask"/>
              </div>
            </div>
            <div class="media-content">
              <h4 class="no-line-height no-letter-space">Task</h4>
              <h5 class="light opacity">Created by {{ task.user.display_name }} {{ task.created_at | moment('from', 'now') }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item is-hidden-mobile">
          <span :class="{ 'tag': true, 'is-complete': isComplete, 'is-incomplete': !isComplete }">{{ status }}</span>
        </div>
        <div class="level-item">
          <snowman-menu
            :items="actions"
            v-on:toggleComplete="toggleComplete"
            v-on:markDeleted="markDeleted"
          />
        </div>
      </div>
    </div>
    <hr class="timeline-box">
    <div class="text-area">
      <em v-if="task.due_at">Due {{ task.due_at | moment('from', 'now') }}</em>
      <h4 style="padding-top:20px;">{{ task.title }}</h4>
      <h4 class="normal no-line-height no-letter-space timeline-text-padding">{{ task.details }}</h4>
    </div>
    <timeline-attachments v-if="task.attachments.length" :attachments="task.attachments"></timeline-attachments>
  </div>
</template>

<style lang="scss" scoped>

  @import "../scss/_theme-default.scss";

  .is-complete {
    background-color: $success;
    color: $white;
  }

  .is-incomplete {
    background-color: $light;
    color: $black;
  }

</style>

<script>

import TimelineAttachments from '@/components/TimelineAttachments'
import SnowmanMenu from '@/components/SnowmanMenu'
import Icon from '@/components/Icons/Icon'
import moment from 'moment'

export default {
  components: {
    TimelineAttachments,
    SnowmanMenu,
    Icon
  },
  computed: {
    url () {
      return process.env.VUE_APP_ROOT_API + 'tasks/' + this.task.id
    },
    isComplete () {
      if (this.task.completed_at) {
        return true
      }
      return false
    },
    status () {
      return (this.isComplete) ? 'Complete' : 'Incomplete'
    },
    actions () {
      const actions = []
      const toggleCompleteName = this.isComplete ? 'Mark Incomplete' : 'Mark Complete'
      actions.push({
        name: toggleCompleteName,
        icon: 'checklist',
        emit: 'toggleComplete'
      })
      actions.push({
        name: 'Delete',
        description: 'Remove the task',
        icon: 'bin',
        emit: 'markDeleted'
      })
      return actions
    }
  },
  methods: {
    toast (message, style) {
      this.$buefy.toast.open({
        message: message,
        type: style
      })
    },
    toggleComplete () {
      if (this.task.completed_at) {
        this.task.completed_at = null
      } else {
        this.task.completed_at = moment().format('YYYY-MM-DD HH:mm:ss')
      }
      this.isLoading = true
      this.$http
        .put(this.url, { task: this.task })
        .then((response) => {
          this.isLoading = false
          this.toast('Task updated.', 'is-success')
        })
        .catch(e => {
          this.task.completed_at = null
          this.isLoading = false
          this.toast(`Error: ${e.message}`, 'is-danger')
        })
    },
    markDeleted () {
      this.isLoading = true
      this.$http
        .delete(this.url)
        .then((response) => {
          this.isLoading = false
          this.task.deleted_at = moment().format('YYYY-MM-DD HH:mm:ss')
          this.toast('Task deleted.', 'is-success')
        })
        .catch(e => {
          this.task.completed_at = null
          this.isLoading = false
          this.toast(`Error: ${e.message}`, 'is-danger')
        })
    }
  },
  name: 'TimelineTask',
  props: ['task']
}
</script>
