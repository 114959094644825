<template>
  <div class="box bunya-box" v-if="note && !note.deleted_at">
    <div class="columns is-mobile">
      <div class="column client-blue-line">
      </div>
    </div>
    <div class="level is-mobile timeline-box-level">
      <div class="level-left">
        <div class="level-item">
          <div class="media is-no-border-media">
            <div class="circle-background-wrapper">
              <div class="circle-background">
                <icon width="30" height="30" stroke="#1BCA8E" fill="white" icon="timelineNote"/>
              </div>
            </div>
            <div class="media-content">
              <h4 class="no-line-height no-letter-space">Note</h4>
              <h5 class="light opacity">Created by {{ note.user.display_name }} {{ note.created_at | moment("from", "now") }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <snowman-menu
            v-on:markDeleted="markDeleted"
            :items="actions"/>
        </div>
      </div>
    </div>
    <hr class="timeline-box">
    <div class="text-area">
      <h4 class="normal no-line-height no-letter-space timeline-text-padding">{{ note.note }}</h4>
    </div>
    <timeline-attachments v-if="note.attachments.length" :attachments="note.attachments"></timeline-attachments>
  </div>
</template>

<script>

import TimelineAttachments from '@/components/TimelineAttachments'
import Icon from '@/components/Icons/Icon'
import SnowmanMenu from '@/components/SnowmanMenu'
import moment from 'moment'

export default {
  components: { TimelineAttachments, Icon, SnowmanMenu },
  name: 'TimelineNote',
  props: ['note'],
  computed: {
    url () {
      return process.env.VUE_APP_ROOT_API + 'notes/' + this.note.id
    }
  },
  methods: {
    toast (message, style) {
      this.$buefy.toast.open({
        message: message,
        type: style
      })
    },
    markDeleted () {
      this.isLoading = true
      this.$http
        .delete(this.url)
        .then(() => {
          this.isLoading = false
          this.note.deleted_at = moment().format('YYYY-MM-DD HH:mm:ss')
          this.toast('Note deleted.', 'is-success')
        })
        .catch(e => {
          this.isLoading = false
          this.toast(`Error: ${e.message}`, 'is-danger')
        })
    }
  },
  data () {
    return {
      isLoading: false,
      actions: [
        {
          name: 'Delete',
          icon: 'bin',
          emit: 'markDeleted'
        }
      ]
    }
  }
}
</script>
