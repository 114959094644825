<template>
  <div class="icon-title">
    <div class="icon-container">
      <icon :icon="icon" :stroke="stroke"></icon>
    </div>
    <div class="icon-text">
      <h2>{{ text }}</h2>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .icon-title {
    /*border: 1px green dashed;*/
    display: inline-flex;
    padding-bottom: 10px;
    align-items: flex-end;
    .icon-container {
      //display: inline-block;
    }
    .icon-text {
      /*border: 1px red dashed;*/
      display: inline-block;
      margin-left: 5px;
      vertical-align: text-bottom;
    }
  }
</style>

<script>

import Icon from '@/components/Icons/Icon'

export default {
  components: { Icon },
  name: 'IconTitle',
  props: ['icon', 'text', 'stroke', 'fill']
}
</script>
