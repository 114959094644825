<template>
  <div class="box bunya-box" v-if="appointment && !appointment.deleted_at">
    <div class="columns is-mobile">
      <div class="column client-blue-line">
      </div>
    </div>
    <div class="level is-mobile timeline-box-level">
      <div class="level-left">
        <div class="level-item">
          <div class="media is-no-border-media">
            <div class="circle-background-wrapper">
              <div class="circle-background">
                <icon width="30" height="30" stroke="#1BCA8E" fill="white" icon="timelineAppointment"/>
              </div>
            </div>
            <div class="media-content">
              <h4 class="no-line-height no-letter-space" v-if="appointment.service">{{ appointment.service.name }}</h4>
              <h4 class="no-line-height no-letter-space" v-else>Appointment</h4>
              <h5 class="light opacity">Created <span v-if="appointment.user">by {{ appointment.user.display_name }}</span> {{ appointment.created_at | moment("from", "now") }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <snowman-menu
            v-on:cancelAppointment="cancelAppointment"
            :items="actions"/>
        </div>
      </div>
    </div>
    <hr class="timeline-box">
    <div class="text-area">
      <h4 style="padding-left: 10px; padding-top:20px; padding-bottom:0px;"><b>Date:</b> {{ appointment.appointment_at | moment('D MMM YYYY') }}</h4>
      <h4 style="padding-left: 10px; padding-top:10px; padding-bottom:10px;"><b>Time:</b> {{ appointment.appointment_at | moment('h:mm a') }}</h4>
      <b-collapse
        aria-id="appointment-forms-expanded-container"
        class="panel"
        animation="slide"
        v-if="appointment.form_entries.length"
        :open.sync="isOpen">
        <div
          slot="trigger"
          class="expand-heading"
          role="button"
          aria-controls="appointment-forms-expanded-container">
          <div class="level is-mobile">
            <div class="level-left">
              <div class="level-item">
                <h3>
                  Form Data
                </h3>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item is-hidden-mobile">
                <h6 class="light">VIEW DETAILS</h6>
              </div>
              <div class="level-item">
                <div class="timeline-circle-expand">
                  <icon class="is-flex" icon="arrowExpand" width="18" height="18"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p v-for="(formData, formDataIndex) in appointment.form_data" :key="formDataIndex">
            <strong>{{ formData.label }}</strong>: {{ formData.value }}
          </p>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>

import Icon from '@/components/Icons/Icon'
import SnowmanMenu from '@/components/SnowmanMenu'
import moment from 'moment'

export default {
  components: { Icon, SnowmanMenu },
  name: 'TimelineAppointment',
  props: ['appointment'],
  computed: {
    url () {
      return process.env.VUE_APP_ROOT_API + 'appointments/' + this.appointment.id
    }
  },
  methods: {
    toast (message, style) {
      this.$buefy.toast.open({
        message: message,
        type: style
      })
    },
    cancelAppointment () {
      this.isLoading = true
      this.$http
        .delete(this.url)
        .then(() => {
          this.isLoading = false
          this.appointment.deleted_at = moment().format('YYYY-MM-DD HH:mm:ss')
          this.toast('Appointment cancelled.', 'is-success')
        })
        .catch(e => {
          this.isLoading = false
          this.toast(`Error: ${e.message}`, 'is-danger')
        })
    }
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      actions: [
        {
          name: 'Cancel',
          icon: 'bin',
          emit: 'cancelAppointment'
        }
      ]
    }
  }
}
</script>
