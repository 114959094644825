<template>
  <div class="box bunya-box">
    <div class="level top-buttons-position is-hidden-touch is-marginless">
      <div class="level-left">
        <div class="level-item is-circle-icon">
          <ul>
            <a :href="clientPhoneUrl" v-if="client.phone">
              <li class="big">
                <icon icon="24_client_phone" stroke="#ABBAC6"/>
              </li>
            </a>
            <a :href="clientEmailUrl" v-if="client.email">
              <li class="big">
                <icon icon="24_client_email" stroke="#ABBAC6"/>
              </li>
            </a>
            <a :href="googleMapsUrl(client.address)" target="_blank" v-if="client.address">
              <li class="big">
                <icon icon="24_client_map" stroke="#ABBAC6"/>
              </li>
            </a>
          </ul>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <a class="client-edit-icon" v-on:click="editClient">
            <icon icon="edit"/>
          </a>
        </div>
      </div>
    </div>
    <hr class="client-separator is-hidden-touch">
    <div class="media is-no-border-media" v-if="client.email">
      <div class="media-content">
        <h6 class="is-uppercase"><b>Email</b></h6>
        <p><a class="profile" :href="clientEmailUrl">{{ client.email }}</a></p>
      </div>
    </div>
    <div class="media is-no-border-media sub">
      <div class="media-content">
        <h6 class="is-uppercase"><b>Phone</b></h6>
        <p class="light" :href="clientPhoneUrl">{{ client.phone }}</p>
      </div>
    </div>
    <div class="media is-no-border-media sub" v-if="client.company">
      <div class="media-content">
        <h6 class="is-uppercase"><b>Company</b></h6>
        <p class="light">{{ client.company }} Company Name</p>
      </div>
    </div>
    <div class="media is-no-border-media sub" v-if="client.address">
      <div class="media-content">
        <h6 class="is-uppercase"><b>Location</b></h6>
        <p class="light" :href="googleMapsUrl(client.address)">{{ client.address }}</p>
      </div>
    </div>
    <div class="media is-no-border-media sub" v-if="client.website">
      <div class="media-content">
        <h6 class="is-uppercase"><b>Website</b></h6>
        <p class="light">{{ client.website }}</p>
      </div>
    </div>
    <hr>
    <p v-if="client.notes">
      <b>Notes</b><br>
      <small>{{ client.notes }}</small>
    </p>
  </div>
</template>

<script>
import Icon from '@/components/Icons/Icon'

export default {
  name: 'ClientDetails',
  components: { Icon },
  computed: {
    clientEmailUrl () {
      return 'mailto:' + this.client.email
    },
    clientPhoneUrl () {
      return 'tel:' + this.client.phone
    }
  },
  methods: {
    googleMapsUrl (address) {
      let cleanAddress = address
      cleanAddress = cleanAddress.replace('\r\n', ', ')
      cleanAddress = cleanAddress.replace('\n', ', ')
      return 'https://maps.google.com/?q=' + cleanAddress
    },
    editClient () {
      this.$router.push('/clients/' + this.client.id + '/edit')
    }
  },
  props: ['client']
}

</script>

<style scoped>
/* .media-content {
  padding-left: 20px;
} */

.color-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  height: 34px;
  border-radius: 17px;
  border: 1px solid #E3E3E3;
  cursor: pointer;
  transition: all 0.5s;
}
.color-container:hover{
  background: #F6FBFC;
}

.top-buttons-position{
  margin-top: 15px;
}

.filter {
  display: flex;
  align-items: center;
}
.color{
  width: 20px;
  height: 20px;
  background-color: #1B8DCA;
  /* border: 2px solid #E5E5E5; */
  border-radius: 50px;
  margin-left: 9px;
}

a.profile {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #007AFF;
}
a.profile:hover {
color: #007AFA;
}

a.dropdown-item.is-active {
  background-color: #fff;
  color: #000;
}

a{
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #42424260;
}
a:hover{
  color: #424242;
}

.client-contact-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-separator{
  background-color: #d8d8d850;
  margin-left: -5px;
  margin-right: -5px;
}

</style>
