<template>
  <div class="box bunya-box no-padding-bottom">
    <div class="level button-container quick-create-buttons is-mobile">
      <div class="level-left">
        <div class="level-item">
          <b-field>
            <b-radio-button v-model="createType" native-value="note" type="bunya-quick-create-button">
              <icon icon="textFile" width="20" height="20" fill="none" class="top-icons-margin"/>
              <span class="has-padding-left is-hidden-touch" @click="queueFocusNote"><h3>Write a Note</h3></span>
              <span class="has-padding-left is-hidden-desktop">Note</span>
            </b-radio-button>
            <b-radio-button v-model="createType" native-value="task" type="bunya-quick-create-button">
              <icon icon="task" width="20" height="20" class="top-icons-margin"/>
              <span class="has-padding-left is-hidden-touch" @click="queueFocusTaskTitle"><h3>Create a Task</h3></span>
              <span class="has-padding-left is-hidden-desktop">Task</span>
            </b-radio-button>
          </b-field>
        </div>
      </div>
      <div class="level-right">
        <div class="level-right">
          <snowman-menu
            v-on:create-type-album="setCreateTypeToAlbum"
            :items="actions"/>
        </div>
      </div>
    </div>
    <div class="create-container">
      <div v-if="createType === 'note'">
        <b-input maxlength="400" type="textarea" v-model="note" :class="'note-textarea'" placeholder="Write a note..." ref="note" ></b-input>
        <div class="level button-container bottom is-mobile">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="level-item">
            <a :class="{ 'button': true, 'is-primary': true, 'is-loading': isLoading }" v-on:click="saveNote" :disabled="isCreateNoteDisabled">Save Note</a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="createType === 'task'">
        <input class="input" type="text" v-model="task.title" placeholder="Task Title..." ref="taskTitle"/>
        <b-input maxlength="200" type="textarea" v-model="task.details" placeholder="A brief task description..."></b-input>
        <div class="level button-container bottom is-mobile">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="level-item">
              <a :class="{ 'button': true, 'is-primary': true, 'is-loading': isLoading }" v-on:click="saveTask" :disabled="isCreateTaskDisabled">Create Task</a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="createType === 'album'">
        <h5 class="subtitle">Create album</h5>
        <file-upload :allow-multiple="true" v-on:processed="updateAlbumFileIds"></file-upload>
        <div class="level button-container bottom is-mobile">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="level-item">
              <a :class="{ 'button': true, 'is-primary': true, 'is-loading': isLoading }" v-on:click="saveAlbum">Save Album</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  .quick-create-buttons {
    .button {
      min-width: 60px;
      opacity: 20%;
      border: 0px;
      padding: 0px 30px 0px 0px;
      outline: none;
      //padding: 0px 100px 0px 0px;
      //color: #5901fd;
      background-color: blue;
      opacity: 100%;
    }
  }

.bunya-quick-create-button:hover {
  outline: none;
  border: 0px;
  //padding: 0px 100px 0px 0px;
  //color: #fd0101;
  //background-color: crimson !important;
}

  .button-container {
    background-color: #fafafa;
    margin: -20px -20px 30px;
    padding: 10px 20px;
    border-top-left-radius: 7.5px;
    border-top-right-radius: 7.5px;
    border-bottom: 1px solid #E3E3E370;
    &.bottom {
      border-radius: 0px 0px 7.5px 7.5px;
      margin-top: 0px;
      margin-bottom: -20px;
      border-top: 1px solid #E3E3E370;
    }
  }

  .create-container .button-container {
    border-bottom: none;
  }

  .bottom-button-margin {
    margin-right: 10px;
  }
  .has-margin-top {
    margin-top: 5px;
  }
  .top-icons-margin {
    margin-bottom: -2px;
  }
  .has-padding-left {
    padding-left: 10px;
  }
  .no-padding-bottom{
    padding-bottom: 0px;
  }
</style>

<script>

import FileUpload from '@/components/FileUpload'
import Icon from '@/components/Icons/Icon'
import SnowmanMenu from '@/components/SnowmanMenu'

export default {
  name: 'TimelineQuickCreate',
  components: { FileUpload, Icon, SnowmanMenu },
  computed: {
    isCreateNoteDisabled () {
      return !this.note
    },
    isCreateTaskDisabled () {
      return !this.task.title
    }
  },
  data () {
    return {
      isLoading: false,
      createType: 'note',
      note: '',
      task: {
        title: '',
        details: ''
      },
      albumFileIds: [],
      actions: [
        {
          name: 'Create Album',
          icon: 'dropdownCreateAlbum',
          emit: 'create-type-album'
        },
        {
          name: 'Create Quote',
          icon: 'dropdownCreateQuote',
          route: { name: 'quotes' }
        },
        {
          name: 'Create Invoice',
          icon: 'dropdownCreateInvoice',
          route: { name: 'invoices-create', params: { preselectedClientId: this.clientId } }
        },
        {
          name: 'Book Appointment',
          icon: 'dropdownCreateAppointment',
          route: { name: 'calendar' }
        }
      ]
    }
  },
  methods: {
    queueFocusTaskTitle () {
      setTimeout(() => {
        this.focusTaskTitle()
      }, 50)
    },
    focusTaskTitle () {
      this.$refs.taskTitle.focus()
    },
    queueFocusNote () {
      setTimeout(() => {
        this.focusNote()
      }, 50)
    },
    focusNote () {
      this.$refs.note.focus()
    },
    setCreateTypeToAlbum () {
      this.createType = 'album'
    },
    updateAlbumFileIds (fileIds) {
      this.albumFileIds = fileIds
    },
    saveNote () {
      const note = {
        client_id: this.clientId,
        note: this.note
      }
      this.isLoading = true
      this.$store.dispatch('notes/create', note)
        .then((note) => {
          this.$store.dispatch('clients/addTimelineEntry', { type: 'note', timelineable: note })
            .then(() => {
              this.afterSave()
            })
        })
        .catch((e) => {
          this.saveErrorHandler(e)
        })
    },
    saveTask () {
      const task = {
        client_id: this.clientId,
        title: this.task.title,
        details: this.task.details
      }
      this.isLoading = true
      this.$store.dispatch('tasks/create', task)
        .then((task) => {
          this.$store.dispatch('clients/addTimelineEntry', { type: 'task', timelineable: task })
            .then(() => {
              this.afterSave()
            })
        })
        .catch((e) => {
          this.saveErrorHandler(e)
        })
    },
    saveAlbum () {
      const album = {
        name: 'New Album',
        client_id: this.clientId,
        file_ids: this.albumFileIds
      }
      this.isLoading = true
      this.$store.dispatch('albums/create', album)
        .then((album) => {
          this.$store.dispatch('clients/addTimelineEntry', { type: 'album', timelineable: album })
            .then(() => {
              this.afterSave()
            })
        })
        .catch((e) => {
          this.saveErrorHandler(e)
        })
    },
    afterSave () {
      this.note = ''
      this.task.details = ''
      this.task.title = ''
      this.albumFileIds = []
      this.createType = 'note'
      this.isLoading = false
    },
    saveErrorHandler (e) {
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger'
      })
    }
  },
  props: ['clientId']
}
</script>
