<template>
  <div class="box bunya-box" style="padding-bottom: 5px;">
    <div class="columns is-mobile">
      <div class="column client-blue-line">
      </div>
    </div>
    <div class="level is-mobile timeline-box-level">
      <div class="level-left">
        <div class="level-item">
          <div class="media is-no-border-media">
            <div class="circle-background-wrapper">
              <div class="circle-background">
                <icon width="30" height="30" stroke="#1BCA8E" fill="#FFFFFF" icon="timelineQuote"/>
              </div>
            </div>
            <div class="media-content">
              <h4 class="no-line-height no-letter-space">Quote {{ quote.quote_number_formatted }}</h4>
              <h5 class="light opacity">Created by {{ quote.user.display_name }} {{ quote.created_at | moment('from', 'now') }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item is-hidden-mobile">
          <span :class="statusClasses">{{ quote.status }}</span>
        </div>
        <div class="level-item">
          <snowman-menu :items="quoteActions"></snowman-menu>
        </div>
      </div>
    </div>
    <hr class="timeline-box">
    <b-collapse
      aria-id="quote-details-expanded-container"
      class="panel"
      animation="slide"
      :open.sync="isOpen">
      <div
        slot="trigger"
        class="expand-heading"
        role="button"
        aria-controls="quote-details-expanded-container">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <h3>
                {{ quote.amount | currency }}
              </h3>
            </div>
            <div class="is-hidden-tablet">
              <span :class="statusClasses">{{ quote.status }}</span>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item is-hidden-mobile">
              <h5 class="light has-text-right">
                {{ quote.created_at | moment('D MMM YYYY') }}
              </h5>
            </div>
            <div class="level-item">
              <div class="timeline-circle-expand">
                <icon class="is-flex" icon="arrowExpand" width="18" height="18"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <timeline-financial-items-table :items="quote.items"></timeline-financial-items-table>
        <timeline-attachments v-if="quote.attachments.length" :attachments="quote.attachments"></timeline-attachments>
      </div>
    </b-collapse>
  </div>
</template>

<script>

import TimelineAttachments from '@/components/TimelineAttachments'
import SnowmanMenu from '@/components/SnowmanMenu'
import TimelineFinancialItemsTable from '@/components/TimelineFinancialItemsTable'
import Icon from '@/components/Icons/Icon'

export default {
  components: { TimelineAttachments, SnowmanMenu, TimelineFinancialItemsTable, Icon },
  data () {
    return {
      invoiceItemsColumns: [
        {
          field: 'description',
          label: 'Item',
          width: '120',
          numeric: false
        },
        {
          field: 'quantity',
          label: 'Quantity',
          width: '40',
          numeric: true
        },
        {
          field: 'unit_price',
          label: 'Unit Price',
          width: '40',
          numeric: true
        },
        {
          field: 'total_price',
          label: 'Total Price',
          width: '40',
          numeric: true
        }
      ],
      isOpen: false
    }
  },
  computed: {
    statusClasses () {
      return {
        tag: true,
        'is-success': this.isInvoiced,
        'is-danger': this.isExpired,
        'is-warning': this.isAccepted,
        'is-primary': this.isEmailed
      }
    },
    isInvoiced () {
      return this.quote.status === 'Invoiced'
    },
    isExpired () {
      return this.quote.status === 'Expired'
    },
    isAccepted () {
      return this.quote.status === 'Accepted'
    },
    isEmailed () {
      return this.quote.status === 'Emailed'
    },
    quoteActions () {
      return [
        {
          name: 'View Quote',
          description: 'See more...',
          icon: 'view',
          route: '/quotes/' + this.quote.id
        },
        {
          name: 'Download PDF',
          description: 'quote-' + this.quote.id + '.pdf',
          icon: 'arrowDownSmall',
          link: this.quote.pdf_link
        },
        {
          name: 'Delete Quote',
          description: 'Quote-be-gone',
          icon: 'bin',
          route: '/quotes/' + this.quote.id + '/delete'
        }
      ]
    }
  },
  name: 'TimelineQuote',
  props: ['quote']
}
</script>
