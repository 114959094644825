<template>
  <div class="has-gray-area timeline-attachments-container">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <span><b-icon icon="paperclip"></b-icon> {{ attachments.length }}</span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item" v-for="(attachment, index) in attachments" :key="index">
          <a :href="attachment.url" target="_blank">
            <b-button rounded icon-right="download">{{ attachment.original_filename }}</b-button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .timeline-attachments-container {
    margin-top: 10pt;
  }
</style>

<script>

export default {
  name: 'TimelineAttachments',
  props: ['attachments']
}

</script>
