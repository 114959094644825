<template>
  <section class="section" v-if="client">
    <div class="client-header is-hidden-touch">
      <div class="container has-bunya-max-width settings-header-margin">
        <div class="line"></div>
        <div class="client-header-content">
          <div class="left-side">
            <div class="logo">{{ client.initials }}</div>
            <div class="text" v-if="client.type === 'business'">
              <h1 class="light rubik">{{ client.contact_person }}</h1>
              <h4 class="light-white">{{ client.name }}</h4>
            </div>
            <div class="text" v-if="client.type === 'individual'">
              <h1 class="light rubik">{{ client.name }}</h1>
            </div>
          </div>
          <div class="right-side">
            <div class="search settings-search is-hidden">
              <b-field class="client-desktop-header-search">
                <b-input placeholder="Search..." type="search" icon="magnify" expanded>
                </b-input>
              </b-field>
              <a class="button is-primary">Create New ...</a>
            </div>
            <div class="information-area">
              <div class="information-box" v-if="client.statistics">
                <div class="item-1">
                  <h1 class="rubik light">
                    <growing-number :value="client.statistics.appointment_count"/>
                  </h1>
                  <h5 class="light-white">Bookings</h5>
                </div>
                <div class="vertical-line"></div>
                <div class="item-2">
                  <h1 class="rubik light">
                    {{ client.statistics.invoiced_total | currency }}
                  </h1>
                  <h5 class="light-white">Total Paid</h5>
                </div>
                <div class="vertical-line"></div>
                <div class="item-2">
                  <h1 class="rubik light">
                    {{ client.statistics.paid_total | currency }}
                  </h1>
                  <h5 class="light-white">Total Invoiced</h5>
                </div>
                <div class="vertical-line"></div>
                <div class="item-last">
                  <h6 class="light-white is-uppercase">Invoice Status</h6>
                  <h5 :class="paymentStatusClass">{{ client.payment_status }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Client Mobile Header -->
    <div class="client-header is-hidden-desktop">
      <div class="container has-bunya-max-width settings-header-margin">
        <nav-bar-mobile></nav-bar-mobile>
        <div class="level is-mobile no-margin-bottom has-wrapper" style="margin-top: 25px; padding-bottom: 25px;">
          <div class="level-left">
            <div class="level-item">
              <div class="client-mobile-header logo">{{ client.initials }}</div>
            </div>
            <div class="level-item has-flex-ability">
              <!-- <h1 class="light rubik">Danni Walker</h1>
              <h4 class="light-white">Burleigh Photos</h4> -->
              <div v-if="client.type === 'business'">
                <h1 class="light rubik">{{ client.contact_person }}</h1>
                <h4 class="light-white">{{ client.name }}</h4>
              </div>
              <div v-if="client.type === 'individual'">
                <h1 class="light rubik">{{ client.name }}</h1>
              </div>
            </div>
          </div>
          <b-modal :active.sync="isModalActive"
                   trap-focus
                   :destroy-on-hide="false"
                   aria-role="dialog"
                   aria-modal>
            <client-details v-if="client" :client="client"></client-details>
          </b-modal>
          <div class="level-right client-info-padding">
            <div class="level-item">
              <div class="right-side with-overflow">
                <div class="client-info-container">
                  <ul class="flex-container">
                    <li class="flex-item">
                      <h6 class="light-white is-uppercase">Invoice Status</h6>
                      <h5 :class="paymentStatusClass">{{ client.payment_status }}</h5>
                    </li>
                    <div class="vertical-line"></div>
                    <li class="flex-item">
                      <h5 class="light-white">Bookings</h5>
                      <h1 class="rubik light">
                        {{ client.statistics.appointment_count }}
                      </h1>
                    </li>
                    <div class="vertical-line"></div>
                    <li class="flex-item">
                      <h5 class="light-white">Total Paid</h5>
                      <h1 class="rubik light">
                        {{ client.statistics.paid_total | currency }}
                      </h1>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->
    <div class="fullwidth-header-sub has-bunya-max-width"></div>
    <div class="columns subpage-information-area-margin client-information-area-margin has-bunya-max-width"
         style="padding-top: 10px;">
      <!--Client Information Box Start-->
      <div class="column is-5 is-hidden-touch">
        <icon-title icon="client" stroke="#1BCA8E" text="Profile"></icon-title>
        <client-details v-if="client" :client="client"></client-details>
        <!-- <a class="button bunya-ghost-button" @click="snackbar">test</a> -->
      </div>
      <!--Client Stream Side Start-->
      <div class="column">
        <icon-title icon="timeline" stroke="#1BCA8E" text="Stream"></icon-title>
        <timeline-quick-create :clientId="client.id"></timeline-quick-create>
        <div class="timeline-container">
          <div class="columns is-mobile is-centered is-hidden">
            <div class="column no-mobile is-3"/>
            <div class="column has-text-centered">
              <div class="upcoming-separator">Upcoming
                <span class="separator-tag">
                  <span class="separator-text">
                    +12 more<icon icon="arrowDownSmall" width="10" height="10" fill="#2D3959"/>
                  </span>
                </span>
              </div>
            </div>
            <div class="column is-3 has-text-right is-hidden">
              <!-- filter part of upcoming separator -->
              <b-dropdown aria-role="list" position="is-bottom-left" v-show="true">
                <a class="filter" slot="trigger" role="button">
                  <!-- <b-icon icon="chevron-down" size="is-small" style="align-items: flex-end;"/>  -->
                  <icon icon="chevronDown" width="10" height="10" style="align-items: flex-end; margin-right: 5px;"
                        class="is-flex"/>
                  <icon icon="filter" width="18" height="16" fill="#AAAAAA"/>
                </a>

                <b-dropdown-item aria-role="listitem">filter by a</b-dropdown-item>
                <b-dropdown-item aria-role="listitem">filter by b</b-dropdown-item>
                <b-dropdown-item aria-role="listitem">filter by c</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="columns is-centered is-mobile is-hidden">
            <div class="column is-3"></div>
            <div class="column is-6 has-text-centered">
              <div class="history-separator">History</div>
            </div>
            <div class="column is-3 has-text-right">
              <!-- filter part of history separator -->
              <b-dropdown aria-role="list" position="is-bottom-left">
                <a class="filter" slot="trigger" role="button">
                  <icon icon="chevronDown" width="10" height="10" style="align-items: flex-end; margin-right: 5px;" class="is-flex"/>
                  <icon icon="filter" width="18" height="16" fill="#AAAAAA"/>
                </a>
                <b-dropdown-item aria-role="listitem">filter by a</b-dropdown-item>
                <b-dropdown-item aria-role="listitem">filter by b</b-dropdown-item>
                <b-dropdown-item aria-role="listitem">filter by c</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="timeline-entry-container" v-for="(te, index) in timeline" :key="index">
            <timeline-appointment v-if="te.type === 'appointment'" :appointment="te.timelineable"></timeline-appointment>
            <timeline-note v-if="te.type === 'note'" :note="te.timelineable"></timeline-note>
            <timeline-task v-if="te.type === 'task'" :task="te.timelineable"></timeline-task>
            <timeline-invoice v-if="te.type === 'invoice'" :invoice="te.timelineable"></timeline-invoice>
            <timeline-quote v-if="te.type === 'quote'" :quote="te.timelineable"></timeline-quote>
            <timeline-album v-if="te.type === 'album'" :album="te.timelineable"></timeline-album>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>

  @import "../scss/_theme-default.scss";
  //MARC Fix dashboard mobile fix
@media only screen and (min-width: 1024px) {
  .client-header {
    padding-left: 15px;
    padding-right: 15px;
  }
}

  .timeline-container {
    padding-top: 30px;
  }

  .mobile-header-columns {
    margin-top: 0;
    //MARC FIX margin-left: -6px;
  }

  .client-header {
    background: #1B8DCA right;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -48px;
  }

  .client-desktop-header-search {
    margin-right: 20px;
    margin-bottom: 0px;
    width: 467px;
  }
  .right-side {
    display: flex;
    align-items: center;
  }
  .client-header-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 25px 30px 20px 20px;
  }

  .left-side {
    display: flex;
    align-items: center;

    .logo {
      width: 70px;
      height: 70px;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      color: #2D3959;
      flex-shrink: 0;
    }

    .text {
      margin-left: 20px;
    }
  }

  .right-side {
    .search {
      display: flex;
      justify-content: flex-end;
      margin-bottom: -10px;
    }

    .information-box {
      //margin-top: 35px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: flex-end;
      background-color: #ffffff00;
      border-radius: 15px;

      .item {
        padding: 15px;
      }

      .item-1 {
        padding-left: 45px;
        padding-right: 45px;
      }

      .item-2 {
        padding-left: 23px;
        padding-right: 23px;
      }

      .item-last {
        padding-left: 16px;
      }
    }

    .status {
      margin-top: 5px;
      border-radius: 30px;
      color: white;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 30px;
    }

    .is-paid {
      background-color: $success
    }
    .is-overdue {
      background-color: $danger
    }
    .is-due {
      background-color: $warning
    }
    is-not-invoiced {
      background-color: $light
    }

    .vertical-line {
      background-color: #ffffff30;
      width: 2px;
      height: 60px;
      border-radius: 1px;
    }
  }

  .has-max-width {
    max-width: 490px;

    &.with-right-margin {
      margin-right: 60px;
    }
  }

  .has-left-margin {
    margin-left: 120px;
  }

  .is-snackbar-button-padding {
    margin-right: 15px;
  }

  .left-align {
    text-align: left;
  }

  .timeline-entry-container {
    margin-bottom: 20pt;
  }

  .history-separator {
    display: inline-block;
    background: #FBFBFB;
    border-radius: 50px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    padding: 6px 35px 8px;
    height: 34px;
    width: 111px;
  }

  .upcoming-separator {
    display: inline-block;
    background: #FBFBFB;
    border-radius: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    color: #2D3959;
    height: 34px;
    width: 168px;

    .separator-tag {
      margin-left: 10px;
      border: 1px solid #EBEBEB;
      border-radius: 12px;
      width: 78px;
      height: 24px;
      display: inline-block;

      .separator-text {
        font-size: 10px;
        font-weight: 500;
        line-height: 20px;

        .icon-container {
          padding-left: 5px;
        }
      }
    }
  }

  a.dropdown-item.is-active {
    background-color: #fff;
    color: #000;
  }

  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #2D395960;
  }

  a:hover {
    color: #fff;
  }

  @media only screen and (max-width: 768px) {
    .no-mobile {
      display: none;
    }
  }

</style>

<script>

import ClientDetails from '@/components/ClientDetails'
import NavBarMobile from '@/components/NavBarMobile'
import TimelineNote from '@/components/TimelineNote'
import TimelineAppointment from '@/components/TimelineAppointment'
import TimelineAlbum from '@/components/TimelineAlbum'
import TimelineTask from '@/components/TimelineTask'
import TimelineInvoice from '@/components/TimelineInvoice'
import TimelineQuote from '@/components/TimelineQuote'
import TimelineQuickCreate from '@/components/TimelineQuickCreate'
import IconTitle from '@/components/IconTitle'
import Icon from '@/components/Icons/Icon'
import GrowingNumber from '@/components/GrowingNumber'

export default {
  props: ['id'],
  name: 'Client',
  components: {
    NavBarMobile,
    ClientDetails,
    IconTitle,
    TimelineNote,
    TimelineAppointment,
    TimelineAlbum,
    TimelineTask,
    TimelineInvoice,
    TimelineQuote,
    TimelineQuickCreate,
    GrowingNumber,
    Icon
  },
  data () {
    return {
      isLoading: false,
      display: true,
      isModalActive: false
    }
  },
  methods: {
    googleMapsUrl (address) {
      let cleanAddress = address
      cleanAddress = cleanAddress.replace('\r\n', ', ')
      cleanAddress = cleanAddress.replace('\n', ', ')
      return 'https://maps.google.com/?q=' + cleanAddress
    },
    editClient () {
      this.$router.push({ name: 'clients-edit', props: { id: this.id } })
    }
  },
  mounted () {
    this.isLoading = true
    this.$store.dispatch('clients/fetch', this.id)
      .then(() => {
        this.isLoading = false
      })
      .catch(e => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
  },
  computed: {
    client () {
      return this.$store.getters['clients/client']
    },
    timeline () {
      return this.$store.getters['clients/timeline']
    },
    primaryAction () {
      return null
    },
    secondaryActions () {
      return []
    },
    paymentStatusClass () {
      if (!this.client) {
        return {}
      }
      return {
        status: true,
        'is-paid': this.client.payment_status === 'Paid',
        'is-due': this.client.payment_status === 'Due',
        'is-overdue': this.client.payment_status === 'Overdue',
        'is-not-invoiced': this.client.payment_status === 'Not Invoiced'
      }
    }
  }
}
</script>
