<template>
  <div class="box bunya-box" style="padding-bottom: 5px;">
    <div class="columns is-mobile">
      <div class="column client-blue-line">
      </div>
    </div>
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <div class="media is-no-border-media">
            <div class="circle-background-wrapper">
              <div class="circle-background">
                <icon width="30" height="30" stroke="#1BCA8E" fill="white" icon="timelineInvoice"/>
              </div>
            </div>
            <div class="media-content">
              <h4 class="no-line-height no-letter-space">Invoice {{ invoice.invoice_number_formatted }}</h4>
              <h5 class="light opacity">Created by {{ invoice.user.display_name }} {{ invoice.created_at | moment("from", "now") }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item is-hidden-mobile">
          <span :class="statusClasses">{{ invoice.status }}</span>
        </div>
        <div class="level-item">
          <snowman-menu
            :items="invoiceActions"
            v-on:show-delete-invoice="showDeleteInvoice"
            v-on:invoice-pdf-download="invoicePDFDownload"
          ></snowman-menu>
        </div>
      </div>
    </div>
    <hr class="timeline-box">
    <b-collapse
      aria-id="invoice-details-expanded-container"
      class="panel"
      animation="slide"
      :open.sync="isOpen">
      <div
        slot="trigger"
        class="expand-heading"
        role="button"
        aria-controls="invoice-details-expanded-container">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <h3>
                {{ invoice.amount | currency }}
              </h3>
            </div>
            <div class="is-hidden-tablet">
              <span :class="statusClasses">{{ invoice.status }}</span>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item is-hidden-mobile">
              <h5 class="light">{{ invoice.created_at | moment('D MMM YYYY') }}</h5>
            </div>
            <div class="level-item">
              <div class="timeline-circle-expand">
                <icon class="is-flex" icon="arrowExpand" width="18" height="18"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <timeline-financial-items-table :items="invoice.items"></timeline-financial-items-table>
        <timeline-attachments v-if="invoice.attachments.length" :attachments="invoice.attachments"></timeline-attachments>
      </div>
    </b-collapse>
  </div>
</template>

<script>

import TimelineAttachments from '@/components/TimelineAttachments'
import SnowmanMenu from '@/components/SnowmanMenu'
import TimelineFinancialItemsTable from '@/components/TimelineFinancialItemsTable'
import Icon from '@/components/Icons/Icon'
import DeleteInvoice from '@/components/DeleteInvoice'

export default {
  components: { TimelineAttachments, SnowmanMenu, TimelineFinancialItemsTable, Icon },
  computed: {
    statusClasses () {
      return {
        tag: true,
        'is-success': this.isPaid,
        'is-danger': this.isOverdue,
        'is-warning': this.isUnpaid,
        'is-primary': this.isEmailed
      }
    },
    invoiceActions () {
      return [
        {
          name: 'View Invoice',
          description: 'See more...',
          icon: 'view',
          route: '/invoices/' + this.invoice.id
        },
        {
          name: 'Download PDF',
          description: this.invoice.invoice_number_formatted + '.pdf',
          icon: 'arrowDownSmall',
          emit: 'invoice-pdf-download'
        },
        {
          name: 'Delete Invoice',
          description: 'Invoice-be-gone',
          icon: 'bin',
          emit: 'show-delete-invoice'
        }
      ]
    },
    isOverdue () {
      return this.invoice.status === 'Overdue'
    },
    isPaid () {
      return this.invoice.status === 'Paid'
    },
    isUnpaid () {
      return this.invoice.status === 'Unpaid'
    },
    isEmailed () {
      return this.invoice.status === 'Emailed'
    },
    isDraft () {
      return this.invoice.status === 'Draft'
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    showDeleteInvoice () {
      this.$buefy.modal.open({
        parent: this,
        component: DeleteInvoice,
        props: {
          invoice: this.invoice
        },
        fullScreen: this.isMobile,
        hasModalCard: true,
        trapFocus: true
      })
    },
    invoicePDFDownload () {
      window.open(this.invoice.pdf_link, '_blank')
    }
  },
  name: 'TimelineInvoice',
  props: ['invoice']
}
</script>
